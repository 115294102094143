import { useState } from 'react';
import useResizeObserver from '@react-hook/resize-observer';
import useLayoutEffect from '@react-hook/passive-layout-effect';

/**
 * A React hook for measuring the size of HTML elements including when they change
 *
 * Forked from @react-hook/size and modified to work with SVGElements too
 *
 * @param target A React ref created by `useRef()` or an HTML element
 * @param options Configures the initial width and initial height of the hook's state
 */
const useSize = <T extends HTMLElement | SVGElement>(
  target: React.RefObject<T> | T | null,
  options?: UseSizeOptions
): [number, number] => {
  const [size, setSize] = useState<[number, number]>([
    options?.initialWidth ?? 0,
    options?.initialHeight ?? 0
  ]);

  useLayoutEffect(() => {
    const targetEl = (
      target && 'current' in target ? target.current : target
    ) as T | undefined;
    if (!targetEl) return;
    const rect = targetEl.getBoundingClientRect();
    setSize([rect.width, rect.height]);
  }, [target]);

  // Where the magic happens
  useResizeObserver(target as HTMLElement, entry => {
    const rect = entry.target.getBoundingClientRect();
    setSize([rect.width, rect.height]);
  });

  return size;
};

export interface UseSizeOptions {
  // The initial width to set into state.
  // This is useful for SSR environments.
  initialWidth: number;
  // The initial height to set into state.
  // This is useful for SSR environments.
  initialHeight: number;
}

export default useSize;
