import { useState } from 'react';
import useLayoutEffect from '@react-hook/passive-layout-effect';

import { breakpoints } from '@styles/theme';

export default function useMatchMedia(
  breakpointInput: string,
  minOrMax: 'min-width' | 'max-width' = 'min-width',
  initialState = false
) {
  const [state, setState] = useState(initialState);

  const breakpoint = breakpoints[breakpointInput] || breakpointInput;
  const mediaQueryString = `(${minOrMax}: ${breakpoint})`;

  useLayoutEffect(() => {
    const mediaQueryList = window.matchMedia(mediaQueryString);

    const updateState = () => setState(mediaQueryList.matches);
    updateState();
    mediaQueryList.addEventListener('change', updateState);

    return () => {
      mediaQueryList.removeEventListener('change', updateState);
    };
  }, [mediaQueryString]);

  return state;
}
