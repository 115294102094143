import type { ComponentStyleConfig } from '@chakra-ui/theme';
import { transition } from '../../styles/defaultAnims';
// Button's variants
export const ButtonVariants: ComponentStyleConfig = {
  baseStyle: {
    w: '100%',
    h: '3.313rem',
    fontFamily: 'copyBold',
    fontSize: '1rem',
    fontWeight: '500',
    border: '0.063rem solid',
    my: '0.313rem',
    px: '1.563rem',
    boxSizing: 'border-box',
    ...transition,
    _disabled: {
      opacity: 0.33,
      pointerEvents: 'none'
    }
  },
  variants: {
    isBlackRadius: {
      bg: 'color.black',
      color: 'color.white',
      borderColor: 'color.black',
      borderRadius: '3.125rem'
    },
    isBlackDefault: {
      bg: 'color.black',
      color: 'color.white',
      borderColor: 'color.black'
    },
    isWhiteRadius: {
      bg: 'color.white',
      color: 'color.black',
      borderRadius: '3.125rem',
      borderColor: 'color.white',
      ...transition
    },
    isWhiteRadiusBorder: {
      bg: 'color.white',
      color: 'color.black',
      borderRadius: '3.125rem',
      borderColor: 'gray.400',
      ...transition
    },
    isWhiteDefault: {
      bg: 'color.white',
      color: 'color.black',
      borderColor: 'gray.400'
    },
    isDisabledRadius: {
      bg: 'gray.400',
      color: 'gray.800',
      borderColor: 'gray.400',
      borderRadius: '3.125rem',
      cursor: 'default',
      pointerEvents: 'none'
    }
  },
  defaultProps: {
    variant: 'isBlackRadius'
  }
};

export const LinkVariants: ComponentStyleConfig = {
  baseStyle: {
    fontFamily: 'copyBold',
    fontWeight: '100',
    fontSize: '1rem',
    textAlign: 'center',
    _hover: {
      textDecoration: 'none'
    }
  },
  variants: {
    isLinkBlack: {
      color: 'color.black'
    },
    isLinkWhite: {
      color: 'color.white'
    },
    isWhiteRadius: {
      fontFamily: 'copyBold',
      fontWeight: '400',
      fontSize: '1rem',
      bg: 'color.white',
      color: 'color.black',
      borderRadius: '3.125rem',
      borderColor: 'color.white',
      padding: '1rem 1rem',
      w: '100%',
      display: 'inline-block',
      textDecoration: 'none',
      ...transition,
      _hover: {
        color: '#fff',
        bg: '#000',
        textDecoration: 'none'
      }
    },
    isBlackRadius: {
      fontFamily: 'copyBold',
      fontWeight: '400',
      fontSize: '1rem',
      bg: 'color.black',
      color: 'color.white',
      borderRadius: '3.125rem',
      borderColor: 'color.black',
      padding: '1rem 1rem',
      w: '100%',
      display: 'inline-block',
      textDecoration: 'none',
      ...transition,
      _hover: {
        color: 'white',
        bg: 'black',
        textDecoration: 'none'
      }
    },
    isLinkGray: {
      textDecoration: 'underline',
      color: 'gray.800',
      _hover: {
        textDecoration: 'underline'
      }
    }
  },
  defaultProps: {
    variant: 'isLinkBlack'
  }
};
