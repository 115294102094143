import { Box, Flex, Text, Heading } from '@chakra-ui/react';
import { useRouter } from 'next/router';

import Button from '@components/Button';
import Link from '@components/Link';
import { useMemo } from 'react';
import { Locale } from '@utils/api';

type ErrorProps = {
  eventId?: string;
  error?: Error;
  notFound?: boolean;
};

type translation = {
  title: string;
  message: {
    generic: string;
    404: string;
  };
  cta: {
    generic: string;
    404: string;
  };
};

const COPY: Record<Locale, translation> = {
  [Locale.ENGLISH_UK]: {
    title: 'Something went wrong…',
    message: {
      generic: 'Refresh the page or click the back button to reload',
      404: `The page you requested wasn't found`
    },
    cta: {
      generic: 'Go to Start page',
      404: 'Go to Nike.com'
    }
  },
  [Locale.ENGLISH_US]: {
    title: 'Something went wrong…',
    message: {
      generic: 'Refresh the page or click the back button to reload',
      404: `The page you requested wasn't found`
    },
    cta: {
      generic: 'Go to Start page',
      404: 'Go to Nike.com'
    }
  },
  [Locale.SPANISH]: {
    title: 'Se ha producido un error.',
    message: {
      generic:
        'Actualiza la página o haz clic en el botón de atrás para volver a cargarla. Ir a la página de inicio',
      404: `The page you requested wasn't found`
    },
    cta: {
      generic: 'Ir a la página de inicio',
      404: 'Go to Nike.com'
    }
  },
  [Locale.ITALIAN]: {
    title: 'Si è verificato un errore.',
    message: {
      generic:
        'Aggiorna la pagina o clicca sul pulsante per tornare indietro e ricaricare.',
      404: `The page you requested wasn't found`
    },
    cta: {
      generic: 'Vai alla pagina iniziale',
      404: 'Go to Nike.com'
    }
  },
  [Locale.GERMAN]: {
    title: 'Etwas ist schiefgelaufen.',
    message: {
      generic:
        'Aktualisiere die Seite oder klicke auf "Zurück", um sie erneut zu laden.',
      404: `The page you requested wasn't found`
    },
    cta: {
      generic: 'Startseite aufrufen ',
      404: 'Go to Nike.com'
    }
  },
  [Locale.TURKISH]: {
    title: 'Bir sorun oluştu…',
    message: {
      generic:
        'Sayfayı yenile veya yeniden yüklemek için geri düğmesine tıkla.',
      404: `The page you requested wasn't found`
    },
    cta: {
      generic: 'Başlangıç sayfasına git',
      404: 'Go to Nike.com'
    }
  },
  [Locale.FRENCH]: {
    title: 'Un problème est survenu…',
    message: {
      generic:
        'Rafraîchis la page ou clique sur le bouton Retour pour la recharger.',
      404: `The page you requested wasn't found`
    },
    cta: {
      generic: 'Aller à la page de depart',
      404: 'Go to Nike.com'
    }
  }
};

export default function Error({ notFound }: ErrorProps) {
  const router = useRouter();

  // Get locale via URL
  const locale = useMemo(() => {
    const locales = Object.values(Locale) as string[];
    const parsed = router.asPath.match(/\/(\w+)\//);

    // ensure we have a valid locale
    if (parsed[1] && locales.includes(parsed[1])) {
      return parsed[1] as Locale;
    }

    return Locale.ENGLISH_UK;
  }, [router]);

  const copy = COPY[locale];

  return (
    <Flex
      h="var(--fold, 100vh)"
      direction="column"
      alignItems="center"
      justifyContent="center"
      textAlign="center"
      color="black"
    >
      <Heading
        as="h1"
        mt="auto"
        mb="10px"
        fontFamily={{ base: 'copy', md: 'heading' }}
        fontSize={{ base: 'sm', md: '2xl' }}
        textTransform={{ md: 'uppercase' }}
      >
        {copy.title}
      </Heading>
      <Text textStyle="copy">
        {notFound ? copy.message['404'] : copy.message.generic}
      </Text>

      <Box
        w="90%"
        maxW={{ md: '14.75rem' }}
        mt={{ base: 'auto', md: 32 }}
        mb={{ base: 4, md: 'auto' }}
      >
        <Button
          href="https://www.nikeactivityfinder.com/"
          content={copy.cta.generic}
          variant="isBlackRadius"
        />
      </Box>
    </Flex>
  );
}
