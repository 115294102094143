import { useState, useEffect } from 'react';

export default function useDimensions(): any {
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  const handleResize = () => {
    const width = window.innerWidth;
    const height = window.innerHeight;
    setDimensions({ width, height });
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return dimensions;
}
