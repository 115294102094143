import { extendTheme } from '@chakra-ui/react';
import { fonts, fontSizes, lineHeights, textStyles } from './textStyles';
import { ArrowButton, Indicator } from '@components/Carousel/styles';
import type { ThemeOverride } from '@chakra-ui/react';

export const breakpoints = {
  // Mobile Breakpoint
  portrait: '17.5rem',
  // in px
  mobile: 600,
  // Desktop Breakpoints
  md: '37.5rem',
  lg: '60rem',
  xl: '120rem',
  '2xl': '90rem',
  // Distance aniimation specific
  desktopDistanceAnimation: '1440px'
};

const theme: ThemeOverride = {
  breakpoints,
  fonts,
  textStyles,
  fontSizes,
  lineHeights,
  styles: {
    global: {
      '*:not(input, textarea)': {
        // userSelect: 'none',
        WebkitTapHighlightColor: 'transparent',
        WebkitOverflowScrolling: 'none',
        WebkitBackfaceVisibility: 'hidden',
        outline: 'none'
      },
      'html, body': {
        background: 'brand.white',
        margin: 0,
        fontSize: '16px',
        color: 'brand.copy',
        fontFamily: 'copy',
        // overflow: 'hidden',
        maxWidth: '100%',
        minHeight: '100%',
        scrollBehavior: 'smooth',
        textRendering: 'optimizeLegibility',
        WebkitFontSmoothing: 'antialiased',
        MozOsxFontSmoothing: 'grayscale'
      },
      html: {
        minHeight: '-webkit-fill-available'
      },
      body: {
        position: 'relative',
        height: '100%'
      },
      '#root': {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        position: 'relative',
        zIndex: 15,
        overflowY: 'auto',
        overscrollBehavior: 'none'
      },
      'div[class*="render-actions__ActionsWrapper"]': {
        display: 'none'
      },
      'div[class*="timer__Text"]': {
        display: 'none'
      },
      'div[class*="countdown"]': {
        fontFamily: 'copyBold',
        opacity: 0.5
      },
      '.SVGInline': {
        display: 'none'
      },
      '.react-html5-camera-photo video': {
        width: '100vw',
        height: 'var(--fold, 100vh)',
        objectFit: 'cover',
        backfaceVisibility: 'visible'
      },
      '#container-circles': {
        position: 'fixed',
        bottom: '7rem'
      },
      '.reactEasyCrop_Container': {
        pointerEvents: 'none'
      }
    }
  },
  sizes: {
    container: {
      base: 'calc(100vw - 70px)',
      md: 'calc(100vw - 160px)',
      lg: 'calc(100vw - 300px)',
      xl: '1200px'
    }
  },
  colors: {
    color: {
      white: '#FFFFFF',
      black: '#000'
    },
    gray: {
      50: '#F9F9F9',
      100: '#F7F7F7',
      200: '#F3F3F3',
      300: '#EEEEEE',
      400: '#E5E5E5',
      500: '#c1c1c1',
      600: '#A7A8A9',
      700: '#959595',
      800: '#757575',
      900: '#525252',
      1000: '#393e49'
    },
    green: '#6CDDA8'
  },
  spacing: {
    xs: '1rem',
    sm: '2rem'
  },
  components: {
    Progress: {
      baseStyle: {
        track: {
          borderRadius: '5px',
          bg: '#0000001a'
        },
        filledTrack: {
          transitionProperty: 'width',
          bg: '#000000'
        }
      },
      defaultProps: {
        colorScheme: 'blackAlpha',
        size: 'xs'
      }
    },
    CarouselArrowButton: ArrowButton,
    CarouselIndicator: Indicator,
    Slider: {
      baseStyle: {
        track: {
          bg: 'gray.400',
          h: 4,
          borderRadius: 'full'
        },
        filledTrack: {
          bg: 'green'
        },
        thumb: {
          boxSize: 6,
          shadow: '0px 3px 10px rgba(0, 0, 0, 0.1)'
        }
      }
    }
  }
};

const customTheme = extendTheme(theme);

export default customTheme;
