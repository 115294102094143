import { keyframes } from '@chakra-ui/react';

const animated = {
  initial: 'inactive',
  animate: 'active'
};

export const fadeUpVariantsWrapper = (delay?: number) => ({
  active: {
    opacity: 1,
    transition: {
      delay: delay || 0,
      delayChildren: 0.5,
      staggerChildren: 0.2
    }
  },
  inactive: {
    opacity: 1
  }
});

export const fadeUpWrapper = (delay?: number) => ({
  ...animated,
  variants: fadeUpVariantsWrapper(delay || 0)
});

export const fadeUpVariants = {
  active: {
    y: 0,
    transition: {
      type: 'spring',
      mass: 0.3,
      damping: 30
    },
    opacity: 1
  },
  inactive: {
    y: 100,
    opacity: 0
  }
};

export const fadeUp = {
  variants: fadeUpVariants,
  width: '100%'
};

export const fadeIn = {
  variants: { active: { opacity: 1 }, inactive: { opacity: 0 } },
  width: '100%'
};

const burstVariants = {
  active: {
    // rotate: 360,
    scale: 2,
    transition: {
      // rotate: {
      //     duration: 50,
      //     repeat: Infinity,
      //     repeatType: 'loop'
      // },
      scale: {
        duration: 5,
        repeat: Infinity,
        repeatType: 'reverse'
      }
    }
  },
  inactive: {
    // rotate: 0,
    scale: 1.5
  }
};

export const burst = {
  position: 'absolute' as const,
  pointerEvents: 'none' as const,
  zIndex: -1,
  ...animated,
  variants: burstVariants
};

const floatVariants = (num: number) => {
  const rndY = Math.random() * num;
  const rndX = (Math.random() * num) / 10;
  const rndRot = Math.random() * (num / 8) - num / 8;

  return {
    active: {
      x: `${rndX}px`,
      y: rndY,
      rotate: rndRot,
      transition: {
        repeat: Infinity,
        repeatType: 'reverse',
        duration: 10
      }
    },
    inactive: {
      x: `-${rndX}px`,
      y: -rndY,
      rotate: -rndRot
    }
  };
};

export const float = (num: number) => ({
  initial: 'inactive',
  animate: 'active',
  variants: floatVariants(num)
});

// Transitions
export const transition = {
  transition: '.2s',
  _hover: {
    transform: 'scale(.94)'
  }
};

// Keyframes for Opacity
export const opacityUp = keyframes`
    0% { opacity: 0 } 
    100% { opacity: 1 }`;
