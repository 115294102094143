import { FC } from 'react';
import { Link, Button } from '@chakra-ui/react';
import { ButtonVariants, LinkVariants } from './variants';

type Variant =
  | keyof typeof ButtonVariants['variants']
  | keyof typeof LinkVariants['variants'];

interface BaseProps {
  content: unknown;
  onClick?: () => void;
  colorLink?: string;
  href?: string;
  textDecoration?: string;
  variant: Variant;
  disabled?: boolean;
  onHref?: () => void;
}

export type NikeButtonProps = BaseProps;

export const NikeButton: FC<NikeButtonProps> = ({
  content,
  onClick,
  onHref,
  href,
  variant,
  ...props
}) => {
  function ButtonStyle(props: { [x: string]: unknown; variant: Variant }) {
    const { variant, ...rest } = props;
    const styles = {
      ...ButtonVariants.baseStyle,
      ...ButtonVariants.variants[variant]
    };
    return <Button __css={styles} {...rest} />;
  }

  function LinkStyle(props: { [x: string]: unknown; variant: Variant }) {
    const { variant, ...restlink } = props;
    const linkstyles = {
      ...LinkVariants.baseStyle,
      ...LinkVariants.variants[variant]
    };
    return <Button as={Link} __css={linkstyles} {...restlink} />;
  }

  const handleOnClick = () => {
    if (href) {
      if (onHref) onHref();
      window.open(href, '_self');
    } else if (onClick) {
      return onClick();
    }
  };

  if (!content) {
    return null;
  }

  if (href) {
    return (
      <LinkStyle
        variant={variant}
        rel="noreferrer"
        onClick={handleOnClick}
        data-testid="href"
        {...props}
      >
        {content}
      </LinkStyle>
    );
  } else {
    return (
      <ButtonStyle
        variant={variant}
        onClick={handleOnClick}
        disabled={props.disabled}
      >
        {content}
      </ButtonStyle>
    );
  }
};
