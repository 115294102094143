import { useThrottle } from '@react-hook/throttle';
import { useEvent } from '@hooks';

/**
 * A small hook to return the size of the window in pixels.
 *
 * You likely want to use the useMatchMedia hook instead of using this one
 * directly.
 */
export default function useWindowSize() {
  if (typeof window === 'undefined') {
    return [0, 0];
  }

  // We're calling hooks conditionally which is against the rules, but in this
  // case it should be okay as the condition always returns true on the client
  // and always returns false on the server

  /* eslint-disable react-hooks/rules-of-hooks */

  const [size, setSize] = useThrottle([window.innerWidth, window.innerHeight]);

  useEvent(window, 'resize', () => {
    setSize([window.innerWidth, window.innerHeight]);
  });

  return size;
}
