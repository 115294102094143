import throttle from 'fetch-throttle';

import queryQuiz from '../queries/quizData.graphql';
import queryCopy from '../queries/copy.graphql';
import queryExternalLinks from '../queries/externalLinks.graphql';
import queryCollections from '../queries/collections.graphql';

import type {
  NikeActivityFinderAnswers,
  NikeActivityFinderQuestionMap,
  NikeActivityFinderQuestions,
  NikeActivityFinderResults,
  NikeActivityFinderCopy,
  NikeActivityFinderExternalLinks,
  NikeActivityFinderResultCollections,
  NikeActivityFinderConditions,
  Directus_Files
} from 'src/queries/directus';

const fetchThrottle = throttle(fetch, 3, 1000);

type Variables = Record<string, unknown>;

export enum Locale {
  ENGLISH_UK = 'en',
  ENGLISH_US = 'en-us',
  SPANISH = 'es',
  FRENCH = 'fr',
  GERMAN = 'de',
  ITALIAN = 'it',
  TURKISH = 'tr'
}

export type Copy = Record<string, string>;

export type ExternalLinks = Record<
  string,
  Record<string, Record<string, string>>
>;

export type ResultCollections = Record<string, NikeActivityFinderResults[]>;

type QueryResult<T> = {
  data: T;
  errors: Error[];
};

export const api = async <T>(
  apiUrl: string,
  query: string,
  variables: Variables = {}
): Promise<QueryResult<T>> => {
  const apiResponse = await fetchThrottle(apiUrl, {
    method: 'POST',
    body: JSON.stringify({ query, variables }),
    headers: {
      'Content-Type': 'application/json'
    }
  });
  const data = await apiResponse.json();

  if (data.errors) {
    data.errors.forEach((e: any) => {
      console.error(e);
    });
    throw new Error(data.errors[0].message);
  }

  return data;
};

export const directus = <T>(query: string, variables?: Variables) =>
  api<T>(`${process.env.DIRECTUS_URL}/graphql`, query, variables);

export type QuizData = {
  results: NikeActivityFinderResults[];
  questions: NikeActivityFinderQuestions[];
  questionMaps: NikeActivityFinderQuestionMap[];
  answers: NikeActivityFinderAnswers[];
  conditions: NikeActivityFinderConditions[];
};

export const quiz = async (language: Locale): Promise<QuizData> => {
  const { data } = await directus<{
    NikeActivityFinderQuestionMap: NikeActivityFinderQuestionMap[];
    NikeActivityFinderQuestions: NikeActivityFinderQuestions[];
    NikeActivityFinderResults: NikeActivityFinderResults[];
    NikeActivityFinderAnswers: NikeActivityFinderAnswers[];
    NikeActivityFinderConditions: NikeActivityFinderConditions[];
  }>(queryQuiz, {
    language
  });

  return {
    results: data.NikeActivityFinderResults,
    questions: data.NikeActivityFinderQuestions,
    questionMaps: data.NikeActivityFinderQuestionMap,
    answers: data.NikeActivityFinderAnswers,
    conditions: data.NikeActivityFinderConditions
  };
};

export const copy = async (
  language: Locale,
  namespace: string
): Promise<Copy> => {
  const { data } = await directus<{
    NikeActivityFinderCopy: NikeActivityFinderCopy[];
  }>(queryCopy, {
    language,
    namespace
  });

  const mapping: Copy = {};

  data.NikeActivityFinderCopy.forEach(copy => {
    mapping[copy.key.trim()] =
      copy.translations.length > 0 ? copy.translations[0].value : '';
  });

  return mapping;
};

export const externalLinks = async (): Promise<ExternalLinks> => {
  const { data } = await directus<{
    NikeActivityFinderExternalLinks: NikeActivityFinderExternalLinks[];
  }>(queryExternalLinks);

  const mapping: ExternalLinks = {};

  data.NikeActivityFinderExternalLinks.forEach(link => {
    mapping[link.key] = mapping[link.key] || {};
    mapping[link.key][link.region] = mapping[link.key][link.region] || {};
    mapping[link.key][link.region][link?.language?.code || 'default'] =
      link.link;
  });

  return mapping;
};

export const collections = async (
  language: Locale
): Promise<ResultCollections> => {
  const { data } = await directus<{
    NikeActivityFinderResultCollections: NikeActivityFinderResultCollections[];
  }>(queryCollections, { language });

  const mapping: ResultCollections = {};

  data.NikeActivityFinderResultCollections.forEach(collection => {
    mapping[collection.key] = [];

    collection.results.forEach(results => {
      const { deeplink, title } =
        results.NikeActivityFinderResults_id.translations[0];
      if (deeplink && title) {
        mapping[collection.key].push(results.NikeActivityFinderResults_id);
      }
    });
  });

  return mapping;
};
