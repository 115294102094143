import type { AppProps } from 'next/app';
import { ChakraProvider } from '@chakra-ui/react';
import useLayoutEffect from '@react-hook/passive-layout-effect';
import { useGARouterTracking, useWindowSize } from '@hooks';
import customTheme from '@styles/theme';
import ErrorBoundary from '@components/ErrorBoundary';

import '@styles/global.css';
import '../src/utils/sentry';

const MyApp = ({ Component, pageProps }: AppProps) => {
  useGARouterTracking();

  const [_width, height] = useWindowSize();
  useLayoutEffect(() => {
    if (!height) return;
    document.body.style.setProperty('--vh', `${height / 100}px`);
  }, [height]);

  // show error screen on prod
  if (process.env.NODE_ENV === 'production') {
    return (
      <ChakraProvider theme={customTheme}>
        <ErrorBoundary>
          <Component {...pageProps} />
        </ErrorBoundary>
      </ChakraProvider>
    );
  }

  return (
    <ChakraProvider theme={customTheme}>
      <Component {...pageProps} />
    </ChakraProvider>
  );
};

export default MyApp;
