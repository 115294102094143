import { defineStyleConfig } from '@chakra-ui/react';

export const ArrowButton = defineStyleConfig({
  baseStyle: props => {
    const isDark = props.colorScheme === 'dark';

    return {
      position: 'absolute',
      zIndex: 2,
      top: '50%',
      transform: 'translateY(-50%)',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      boxSize: { base: '38px', md: '80px' },
      bg: isDark ? 'black' : 'white',
      color: isDark ? 'white' : 'black',
      borderRadius: 'full',
      transition: '.2s background, .2s opacity',
      _hover: { bg: 'gray.800' },
      _active: { bg: 'gray.800' },
      ...{ [props.direction]: { base: '0', md: '8%' } },
      ...(props.hasNext ? {} : { opacity: 0, pointerEvents: 'none' })
    };
  },
  defaultProps: {
    colorScheme: 'light'
  }
});

export const Indicator = defineStyleConfig({
  baseStyle: props => ({
    boxSize: 2,
    mx: 1,
    cursor: 'pointer',
    borderRadius: 'full',
    bg: props.isSelected ? '#000' : '#0000001a'
  })
});
