export const fonts = {
  heading: 'Nike, sans-serif',
  alternativeHeading: 'NikeFutura, sans-serif',
  copy: 'HelveticaNeue, sans-serif',
  copyBold: 'HelveticaNeueBold, sans-serif',
  copyThin: 'HelveticaNeueThin, sans-serif'
};

export const fontSizes = {
  xxs: '0.75rem',
  xs: '0.875rem',
  sm: '1rem',
  md: '1.25rem',
  lg: '1.5rem',
  xl: '2rem',
  '2xl': '3rem',
  '3xl': '3.25rem',
  '4xl': '4rem',
  '5xl': '4.375rem',
  '6xl': '8.5rem'
};

export const lineHeights = {
  xxs: '0.5rem',
  xs: '0.7rem',
  sm: '0.75rem',
  md: '1.25rem',
  lg: '1.75rem',
  xl: '2.25rem',
  '2xl': '2.5rem',
  '3xl': '3rem',
  '4xl': '3.75rem',
  '5xl': '4.5rem',
  '6xl': '10rem'
};

export const rem = (px: number, base = 16) => {
  return (1 / base) * px + 'rem';
};

const heading = {
  fontFamily: fonts.heading,
  color: 'brand.white',
  letterSpacing: 'initial',
  textTransform: 'uppercase',
  mb: { base: '0.625rem', md: '1rem' }
};

const h1 = {
  ...heading,
  textTransform: 'uppercase',
  fontSize: { base: 'xl', md: '3xl', lg: '4xl' },
  lineHeight: { base: 'xl', md: '3xl', lg: '4xl' }
};

const h2 = {
  ...heading,
  fontSize: { base: 'lg', md: '2xl', lg: '3xl' },
  lineHeight: { base: 'md', md: '2xl', lg: '3xl' }
};

const h3 = {
  ...heading,
  fontSize: { base: 'sm', md: 'xl', lg: '2xl' },
  lineHeight: { base: 'md', md: 'xl', lg: '2xl' }
};

const h4 = {
  ...heading,
  fontFamily: fonts.copy,
  fontWeight: 600,
  fontSize: { base: 'sm', md: 'lg', lg: 'xl' },
  lineHeight: { base: 'md', md: 'lg', lg: 'xl' }
};

const buttonText = {
  ...heading,
  letterSpacing: 0,
  fontSize: 'lg',
  lineHeight: 'sm'
};

export const textStyles = {
  h1,
  h2,
  h3,
  h4,
  button: {
    ...buttonText
  },
  buttonSmall: {
    ...buttonText,
    fontSize: 'xxs',
    lineHeight: 'sm'
  },
  copy: {
    color: 'black',
    fontFamily: 'copy',
    fontWeight: 400,
    fontSize: { base: 'xs', md: 'md' },
    lineHeight: { base: 'md', md: 'lg' }
  },
  copyThin: {
    color: 'black',
    fontFamily: 'copy',
    fontWeight: 400,
    fontSize: { base: 'xs', md: 'md' },
    lineHeight: { base: 'md', md: 'md' },
    letterSpacing: 0.8
  },
  copyBold: {
    color: 'gray.800',
    fontFamily: 'copyBold',
    fontWeight: 800,
    fontSize: { base: 'xs', sm: 'sm', md: 'md' },
    lineHeight: { base: 'xs', sm: 'sm', md: 'md' }
  },
  links: {
    color: 'gray.800',
    fontFamily: 'copy',
    fontWeight: 800,
    fontSize: '0.6rem',
    textDecoration: 'underline'
  },
  small: {
    color: 'gray.800',
    fontFamily: 'copyThin',
    fontSize: 'xs',
    lineHeight: 'sm'
  },
  tiny: {
    color: 'gray.800',
    whiteSpace: 'nowrap',
    fontFamily: 'copyThin',
    fontSize: 'xxs'
  },
  tinyLink: {
    color: 'gray.800',
    whiteSpace: 'nowrap',
    fontFamily: 'copyThin',
    fontSize: '0.675rem',
    textDecoration: 'none',
    cursor: 'pointer',
    _hover: {
      textDecoration: 'underline'
    }
  },
  slideTitle: {
    color: 'color.black',
    fontSize: {
      base: '2rem',
      md: '3.5rem'
    },
    fontWeight: '500',
    fontStyle: {
      base: 'unset',
      md: 'italic'
    },
    fontFamily: 'alternativeHeading',
    textTransform: 'uppercase',
    maxWidth: '57.438rem',
    mx: {
      md: 'auto'
    },
    textAlign: {
      base: 'left',
      md: 'center'
    }
  }
};
