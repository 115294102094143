import { useEffect, useRef } from 'react';

/**
 * Modified from @react-hook/event to a) work and b) support options
 */
function useEvent<
  T extends HTMLElement | SVGElement | Window =
    | HTMLElement
    | SVGElement
    | Window,
  K extends
    | keyof HTMLElementEventMap
    | keyof SVGElementEventMap
    | keyof WindowEventMap =
    | keyof HTMLElementEventMap
    | keyof SVGElementEventMap
    | keyof WindowEventMap
>(
  target: React.RefObject<T> | T | null,
  type: K,
  listener: EventListener,
  options?: boolean | AddEventListenerOptions
) {
  const storedListener = useRef(listener);

  useEffect(() => {
    const targetEl = (
      target && 'current' in target ? target.current : target
    ) as T;
    if (!targetEl) return;

    let didUnsubscribe = 0;
    const listener: EventListener = e => {
      if (didUnsubscribe) return;
      storedListener.current(e);
    };

    targetEl.addEventListener(type, listener, options);

    return () => {
      didUnsubscribe = 1;
      targetEl.removeEventListener(type, listener);
    };

    // options isn't reactive for now
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [target, type]);

  useEffect(() => {
    storedListener.current = listener;
  }, [listener]);
}

export default useEvent;
